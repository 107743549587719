import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Checkbox, CircularProgress, FormHelperText } from "@mui/material";

export default function Dropdown({
  label,
  items,
  value,
  onChange,
  warn,
  size = "",
  disabled,
  multiple,
  width,
  variant,
  hideDefaultValue,
}) {
  return (
    <Box sx={{ position: "relative", minWidth: width ? width : 120, paddingLeft: 0 }}>
      <FormControl
        size={size}
        fullWidth
        error={warn}
        disabled={disabled}
        style={{ paddingLeft: "0px" }}
      >
        <InputLabel style={{ color: "#808080", marginLeft: "-2px" }}>{label}</InputLabel>
        <Select
          value={value || "0"}
          defaultValue={value || "0"}
          variant={variant || "outlined"}
          defaultChecked={value || "0"}
          style={{ background: "transparent" }}
          label={label}
          disabled={disabled || !items}
          multiple={multiple}
          renderValue={(selected) => {
            if (multiple && items) {
              const newSelected = items?.map((item) => {
                if (selected.includes(item?.value)) {
                  return item?.name;
                } else {
                  return false;
                }
              });
              return newSelected.filter((select) => select !== false).join(", ");
            } else if (items) {
              return items?.find((item) => selected === item.value)?.name;
            }
          }}
          onChange={(e) =>
            multiple && e.target.value.includes("resetSelections")
              ? onChange({ value: [] })
              : onChange(e.target.value)
          }
        >
          {!hideDefaultValue && (
            <MenuItem value={multiple ? "resetSelections" : "0"}>
              <em>{multiple ? "--Reset Selections--" : "--Select--"}</em>
            </MenuItem>
          )}
          {items &&
            items.map((item) => {
              return (
                <MenuItem
                  key={label + "-" + item?.name}
                  value={item?.value}
                >
                  {multiple && <Checkbox checked={value.indexOf(item?.value) > -1} />}
                  {item?.name}
                </MenuItem>
              );
            })}
        </Select>
        {warn && <FormHelperText>This field is mandatory.</FormHelperText>}
      </FormControl>
      {!items && (
        <CircularProgress
          size={30}
          style={{
            position: "absolute",
            top: "12px",
            right: "10px",
            background: "white",
          }}
        />
      )}
    </Box>
  );
}

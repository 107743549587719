import {
  Button,
  Grid,
  Dialog,
  Slide,
  Alert,
  DialogActions,
  DialogContent,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import Dropdown from "src/components/Dropdown";
import PageLoading from "src/components/PageLoading";
import SendIcon from "@mui/icons-material/Send";
import HtmlEditor from "src/components/HtmlEditor";
import Input from "src/components/Input";

import sendRequest from "src/helpers/service";
import handleError from "src/helpers/errorHandler";
import addFocusEvent from "src/helpers/focusHandler";
import { MainContext } from "src/state/state";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function MailModal({ data, onClose }) {
  const { setLoading } = useContext(MainContext);

  const [attempted, setAttempted] = useState(false);
  const [type, setType] = useState("template");
  const [templates, setTemplates] = useState([]);

  const [selectedTemplate, setselectedTemplate] = useState();
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused) {
      (async () => {
        try {
          // setLoading(true);
          sendRequest
            .get({ url: "mail/listfordropdown", data: { to: data.to } })
            .then((res) => {
              setTemplates(res.data);
            })
            .catch((err) => {
              handleError(err);
            });
          // setLoading(false);
        } catch (error) {
          handleError(error);
        }
      })();
    }
  }, [isFocused]); //eslint-disable-line

  useEffect(() => {
    const unsubscribe = addFocusEvent({
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
    });

    sendRequest
      .get({ url: "mail/listfordropdown?to=newsletter" })
      .then((res) => {
        setTemplates(res.data);
      })
      .catch((err) => {
        handleError(err);
        onClose();
      });

    return unsubscribe;
  }, []); //eslint-disable-line

  async function handleSend() {
    try {
      setAttempted(true);
      if ((type === "template" && !selectedTemplate) || (type === "html" && !content)) return;

      setAttempted(false);
      setLoading(true);

      if (data.bulk) {
        await sendRequest.post({
          url: "mail/send-bulk-email",
          data: {
            receiver_type: data.to,
            template: selectedTemplate,
            mail_content: content,
            subject,
            type,
          },
        });
        toast("Email send successfully to list", {
          theme: "colored",
          type: "success",
        });
      } else {
        await sendRequest.post({
          url: "mail/send-single-email",
          data: {
            receiver_id: data.receiver_id,
            receiver_type: data.receiver_type,
            type,
            mail_content: content,
            subject,
            template: selectedTemplate,
            email: data.email,
            name: data.name,
          },
        });
        toast("Email send successfully to '" + data.email + "'", {
          theme: "colored",
          type: "success",
        });
      }
      onClose();
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  }

  if (!templates) return <PageLoading />;

  let sendingTo = "";
  if (data.to === "customers") {
    sendingTo = "All Customers";
  } else if (data.to === "newsletter") {
    sendingTo = "Newsletter";
  } else {
    sendingTo = data.email;
  }

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={onClose}
      // aria-describedby="alert-dialog-slide-description"
    >
      <Alert
        icon={<SendIcon />}
        severity="info"
        sx={{ alignItems: "center" }}
      >
        <h5 className="mb-0">Send Email</h5>
      </Alert>

      <DialogContent>
        <Grid
          item
          xs={12}
          mb={3}
        >
          <ToggleButtonGroup
            color="info"
            value={type}
            onChange={(e, value) => setType(value)}
            exclusive
            aria-label="Platform"
          >
            <ToggleButton value="template">Template</ToggleButton>
            <ToggleButton value="new">New Email</ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {type === "template" ? (
          <Grid
            item
            xs={12}
            md={6}
            mb={3}
          >
            {data.to === "newsletter" && (
              <Alert
                sx={{ mb: 3 }}
                severity="warning"
              >
                You cannot send newsletter email if there is name or similar parameters in the
                template
              </Alert>
            )}

            <Dropdown
              label="Select Template"
              value={selectedTemplate}
              onChange={setselectedTemplate}
              items={templates}
              warn={attempted && !selectedTemplate}
            />
            <Link
              style={{ fontSize: 13 }}
              target="_blank"
              to={"/mail-templates"}
            >
              Update Templates
            </Link>
          </Grid>
        ) : (
          <>
            <Grid
              item
              xs={12}
              mb={3}
            >
              <Input
                label={"Subject"}
                variant="outlined"
                value={subject}
                onChange={setSubject}
                warn={attempted && subject.length === 0}
                warning={"Subject is Required"}
              />
            </Grid>
            <Grid
              item
              xs={12}
              mb={3}
            >
              <HtmlEditor
                value={content}
                onChange={setContent}
                placeholder={"Mail Content..."}
              />
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Typography
          style={{ position: "absolute", bottom: 0, left: "2%" }}
          color={"gray"}
          fontSize={13}
          mb={2}
        >
          Sending Email to: {sendingTo}
        </Typography>
        <Button
          style={{ color: "red" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          style={{ color: "green" }}
          onClick={handleSend}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}

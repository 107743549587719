import React, { useReducer } from "react";
import { mainReducer } from "./reducer";
import { tokenRef } from "src/helpers/service";

const getDefaultState = () => {
  let user = localStorage.getItem("user");
  const pages = localStorage.getItem("p");
  try {
    if (user && pages) {
      try {
        user = JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("user"))));
      } catch (error) {
        localStorage.removeItem("user");
      }
      tokenRef.current = user.token;

      return {
        user,
        userAccessPages: JSON.parse(decodeURIComponent(window.atob(localStorage.getItem("p")))),
      };
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("p");
      return { user: false, userAccessPages: [] };
    }
  } catch (error) {
    return { user: false, userAccessPages: [] };
  }
};

const defaultState = getDefaultState();

export const UserContext = React.createContext({
  contactUsCount: 0,
  ...defaultState,
});

export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultState);

  const setUser = (payload) => {
    dispatch({ type: "SET_USER", payload });
  };

  const logout = (payload) => {
    localStorage.removeItem("user");
    localStorage.removeItem("p");
    dispatch({ type: "LOGOUT", payload });
    window.navigate("/login");
  };

  const setUserAccessPages = (payload) => {
    if (!payload) return;

    payload = payload.map((page) => ({
      name: page.name,
      write: page.write_permission,
      read: page.read_permission,
      delete: page.delete_permission,
      page_id: page.page_id,
    }));

    localStorage.setItem("p", window.btoa(encodeURIComponent(JSON.stringify(payload))));

    dispatch({ type: "SET_USER_ACCESS_PAGES", payload });
  };

  const setContactUsCount = (payload) => {
    dispatch({ type: "SET_COUNTACT_US_COUNT", payload });
  };

  const value = {
    user: state.user,
    contactUsCount: state.contactUsCount,
    userAccessPages: state.userAccessPages,
    logout,
    setUser,
    setContactUsCount,
    setUserAccessPages,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

import { Link, useLocation, useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const location = useLocation();
  const Icon = item.icon;

  const path = location.pathname.split("/");
  const isSelected = location.pathname === "/" ? item.id === "/" : path[1] === item.id;
  return (
    <Link
      to={item.url}
      style={{ textDecoration: "none" }}
    >
      <ListItemButton
        disabled={item.disabled}
        sx={{
          width: "100%",
          borderRadius: `10px`,
          mb: 0.5,
          alignItems: "flex-start",
          backgroundColor: "transparent",
          py: 1,
          pl: item.icon ? `24px` : 0,
          color: "#000",
          "&:hover": {
            // backgroundColor: theme.palette.primary.light3 ,
            color: "black",
          },
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: "white",
          },
          "&.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
        selected={isSelected}
      >
        {item.icon && (
          <ListItemIcon sx={{ my: "auto", minWidth: 18, color: isSelected ? "white" : "black" }}>
            <Icon
              stroke={2}
              size="1.5rem"
              color={isSelected ? "white" : "black"}
            />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              color="inherit"
              sx={{ marginLeft: "10px", fontSize: "14px", fontWeight: "500" }}
            >
              {item.title}
            </Typography>
          }
          secondary={
            item.caption && (
              <Typography
                variant="caption"
                sx={{ ...theme.typography.subMenuCaption }}
                display="block"
                gutterBottom
              >
                {item.caption}
              </Typography>
            )
          }
        />
        {item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>
    </Link>
  );
};

export default NavItem;

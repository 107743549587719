import React from "react";
import { Box } from "@mui/material";

import logo from "../../../assets/images/logo.svg";

const Logo = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt="Klooma Logo"
        width="100"
      />
    </Box>
  );
};

export default Logo;

import React from "react";
import { Autocomplete, TextField } from "@mui/material";

export default function TagInput({ values, onChange, label, placeholder, options, id }) {
  function handleUpdate(event, value) {
    onChange(value);
  }

  function getOptionLabel(option) {
    if (options) {
      return option.name;
    } else {
      return option;
    }
  }

  return (
    <Autocomplete
      multiple
      id={id || "tags-standard"}
      options={options || values}
      getOptionLabel={getOptionLabel}
      value={values}
      onChange={handleUpdate}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
          onKeyDown={(e) => {
            const value = e.target.value.trim();
            if (e.keyCode === 13 && value) {
              if (!values.includes(value)) onChange([...values, value]);
            }
          }}
        />
      )}
    />
  );
}

import axios from "axios";
import { createRef } from "react";
const querystring = require("querystring");
export const tokenRef = createRef();

const sendRequest = {
  post: (params) => handleRequest(params, "post"),
  put: (params) => handleRequest(params, "put"),
  get: (params) => handleRequest(params, "get"),
  delete: (params) => handleRequest(params, "delete"),
};

export default sendRequest;

function handleRequest(params, method) {
  return new Promise((resolve, reject) => {
    if (!params.url) {
      reject({ message: "URL is required for http request" });
      return;
    }

    if (params.data && ["get", "delete"].includes(method)) {
      params.url += "?" + querystring.stringify(params.data);
    }

    request
      .request({
        method,
        responseType: params.responseType,
        url: params.url,
        data: params.data,
        config: params.config,
        onUploadProgress: params.onUploadProgress,
        headers: {
          ...params.headers,
          // "Content-Type": "application/json",
          Authorization: "Bearer " + tokenRef.current,
        },
      })
      .then((res) => {
        resolve(res?.data);
      })
      .catch((err) => reject(err));
  });
}

const request = axios.create({
  baseURL: process.env.REACT_APP_CUSTOM_BASE_URL || "http://selfserviceapi.klooma.com/api",
});

request.interceptors.response.use(
  async function (config) {
    return config;
  },
  async function (error) {
    const err = error.toJSON();
    if (err?.status === 401) {
      window.handleLogout();
    }
    return Promise.reject(error);
  }
);

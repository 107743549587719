/*
  * if there is no "read permission" for main controller, we will redirect to 403
  * boolean parameter means, its the main(required for the page) permission for that spesific page. 
*/
export const permissionMap = {
  dashboard: {
    reports: true,
  },
  content_dashboard: {
    reports: true,
  },
  panelUsers: {
    panel_user_informations: true, // Main controller for panel users
  },
  createPanelUser: {
    panel_user_informations: true, // Main controller for panel users
  },
  editPanelUSer: {
    panel_user_informations: true, // Main controller for panel users
  },
  music: {
    music: true,
    reports: false,
  },
  music_video: {
    music_video: true,
    reports: false,
  },
  movies: {
    movies: true,
    settings: false,
    reports: false,
  },


  customers: {
    customers: true, // Main controller for customers
    reports: false,
  },

  film_makers: {
    film_makers: true,
  },

  admins: {
    admins: true, // Main controller for customers
  },

  roles: {
    roles: true,
  },

  settings: {
    settings: true,
  },
  translations: {
    translations: true,
  },
  faq: {
    faq: true,
  },
  configurations: {
    configurations: true,
  },
  join_the_network: {
    join_the_network: true,
    film_makers: false,
  },
  contact_forms: {
    contact_forms: true,
    customers: true,
  },
  newsletter: {
    newsletter: true,
  },
  subscription: {
    subscription: true,
  },
  mail: {
    mail: true,
  },
  sony_ingestion: {
    reports: true,
  },
};

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";

import { CheckCircleOutline, ErrorOutlineOutlined, InfoOutlined } from "@mui/icons-material";
import { Alert } from "@mui/material";
import Input from "../Input";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DialogModal({ modal, onClose }) {
  const [validationText, setValidationText] = useState("");
  const [attempted, setAttempted] = useState(false);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      keepMounted
      onClose={!modal.notClose && onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      {modal.type === "success" && (
        <Alert
          icon={<CheckCircleOutline fontSize="inherit" />}
          severity="success"
          sx={{ alignItems: "center" }}
        >
          <h5 className="mb-0">{modal.title}</h5>
        </Alert>
      )}
      {modal.type === "error" && (
        <Alert
          icon={<ErrorOutlineOutlined fontSize="inherit" />}
          severity="error"
          sx={{ alignItems: "center" }}
        >
          <h5 className="mb-0">{modal.title}</h5>
        </Alert>
      )}
      {modal.type === "info" && (
        <Alert
          icon={<InfoOutlined fontSize="inherit" />}
          severity="info"
          sx={{ alignItems: "center" }}
        >
          <h5 className="mb-0">{modal.title}</h5>
        </Alert>
      )}
      {(modal.type === "warning" || modal.type === "validation") && (
        <Alert
          icon={<ErrorOutlineOutlined />}
          severity="warning"
          sx={{ alignItems: "center" }}
        >
          <h5 className="mb-0">{modal.title}</h5>
        </Alert>
      )}
      {!modal.type && <h5 className="mb-0">{modal.title}</h5>}
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          <span dangerouslySetInnerHTML={{ __html: modal.message }}></span>
        </DialogContentText>
        {modal.type === "validation" && (
          <Input
            // label={"Enter Your Code"}
            value={validationText}
            onChange={setValidationText}
            variant={"outlined"}
            onEnterPress={() => {
              modal.cancelButton.onClick && modal.cancelButton.onClick();
              onClose();
            }}
            style={{ marginTop: 100 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        {modal.cancelButton && (
          <Button
            style={{
              color: modal.cancelButton.color || "black",
            }}
            onClick={() => {
              modal.cancelButton.onClick && modal.cancelButton.onClick();
              onClose();
            }}
          >
            {modal.cancelButton.text}
          </Button>
        )}
        {modal.confirmButton && (
          <Button
            style={{
              color:
                modal.type === "validation"
                  ? validationText === modal.validationText
                    ? modal.confirmButton.color
                    : "gray"
                  : modal.confirmButton.color || "black",
            }}
            disabled={modal.type === "validation" && modal.validationText !== validationText}
            onClick={() => {
              if (validationText) setValidationText("");

              modal.confirmButton.onClick && modal.confirmButton.onClick();
              onClose();
            }}
          >
            {modal.confirmButton.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

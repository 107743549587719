import React, { useContext, useEffect, useState } from "react";
import {
  Grid,
  Divider,
  Button,
  Autocomplete,
  TextField,
  FormControlLabel,
  Switch,
} from "@mui/material";

import Input from "../../../components/Input";
import Dropdown from "../../../components/Dropdown";
import TagInput from "../../../components/TagInput";

import { MainContext } from "../../../state/state";
import sendRequest from "../../../helpers/service";
import { toast } from "react-toastify";
import handleError from "src/helpers/errorHandler";

import { Link, useNavigate } from "react-router-dom";
import addFocusEvent from "src/helpers/focusHandler";
import PageLoading from "src/components/PageLoading";
import moment from "moment";
import DateInput from "src/components/DateInput";

export default function GeneralTab({ permission, isEdit, id, detail }) {
  const { setModal, setLoading } = useContext(MainContext);
  const navigate = useNavigate();

  const [attempted, setAttempted] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  //if user wants to publish it, we need to light all reuqired fields with this state
  const [statusAttempted, setStatusAttempted] = useState(false);

  const [categoryList, setCategoryList] = useState(false);
  const [ownerList, setOwnerList] = useState(false);
  const [tags, setTags] = useState([]);

  const [selectedOwner, setSelectedOwner] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [title, setTitle] = useState("");
  const [releaseDate, setReleaseDate] = useState(moment());
  const [tvStatus, setTvStatus] = useState(false);
  const [webStatus, setWebStatus] = useState(false);
  const [mobileStatus, setMobileStatus] = useState(false);
  const [director, setDirector] = useState("");
  const [scriptWriter, setScriptWriter] = useState("");
  const [ageRestriction, setAgeRestriction] = useState(0);

  useEffect(() => {
    setStatusAttempted(false);
  }, [tvStatus, webStatus, mobileStatus]);

  useEffect(() => {
    if (isFocused) {
      (async () => {
        try {
          setLoading(true);
          const categoryRes = await sendRequest.get({ url: "genre/listfordropdown" });
          setCategoryList(categoryRes.data);

          const ownerRes = await sendRequest.get({ url: "artist/listfordropdown" });
          setOwnerList(ownerRes.data);

          setLoading(false);
        } catch (error) {
          handleError(error);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    const unsubscribe = addFocusEvent({
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
    });

    (async () => {
      try {
        const categoryRes = await sendRequest.get({ url: "genre/listfordropdown" });
        setCategoryList(categoryRes.data);

        const ownerRes = await sendRequest.get({ url: "artist/listfordropdown" });
        setOwnerList(ownerRes.data);

        if (detail) {
          setTitle(detail.album_name);
          setTvStatus(detail.tv_status === 1);
          setWebStatus(detail.web_status === 1);
          setMobileStatus(detail.mobile_status === 1);
          setReleaseDate(detail.release_date ? moment(detail.release_date) : null);
          setSelectedOwner(parseInt(detail.owner_id));
          setAgeRestriction(detail.age_restriction || "");
          setDirector(detail.director || "");
          setScriptWriter(detail.script_writer || "");

          if (detail.tags) {
            setTags(detail.tags.split(","));
          }

          if (detail.genres) {
            setSelectedCategories(
              detail.genres.split(",").map((genreId) => {
                //eslint-disable-next-line
                return categoryRes.data.find((genre) => genre.value == parseInt(genreId));
              })
            );
          }
        }
      } catch (error) {
        handleError(error);
      }
    })();

    return unsubscribe;
  }, []); //eslint-disable-line

  function handleStatusChange(event, setStatus) {
    setStatus(event.target.checked);
  }

  const handleSubmit = async () => {
    setAttempted(true);
    //eslint-disable-next-line
    if (!title || selectedOwner == "0") {
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("owner", selectedOwner);
      formData.append("genres", selectedCategories.map((category) => category.value).join(","));
      formData.append("release_date", releaseDate);
      formData.append("tags", tags.join(","));
      formData.append("tv_status", tvStatus ? 1 : 0);
      formData.append("web_status", webStatus ? 1 : 0);
      formData.append("mobile_status", mobileStatus ? 1 : 0);
      formData.append("director", director);
      formData.append("script_writer", scriptWriter);
      formData.append("age_restriction", ageRestriction || 0);

      if (isEdit) {
        const updateRes = await sendRequest.post({
          url: "/music/update-general/" + id,
          data: formData,
        });

        if (updateRes.data.status_error) {
          setWebStatus(false);
          setTvStatus(false);
          setMobileStatus(false);
          toast(
            "Status become passive for the following credentials: \n" + updateRes.data.status_error,
            {
              theme: "dark",
              type: "warning",
            }
          );
        } else {
          toast("Updated successfully", {
            theme: "colored",
            type: "success",
          });
        }
      } else {
        const res = await sendRequest.post({ url: "/music/save", data: formData });
        navigate("/music/edit/" + res.data.id, {
          relative: true,
          replace: true,
          state: Math.random(),
        });
        toast("Music Created successfully", {
          theme: "colored",
          type: "success",
        });
      }
    } catch (error) {
      handleError(error);
    }
    setLoading(false);
  };

  const handleDelete = () => {
    setModal({
      type: "delete",
      title: "Delete Album",
      apiUrl: `/music/${detail.id}`,
      message: `Are you sure to delete this music("${detail.album_name}")?`,
      successText: "Music deleted successfully",
      validation: true,
      validationText: detail.name_en,
      warnings: ["All content will be deleted. <b>This cannot be reverted</b>"],
      options: [
        {
          keyword: "delete_transactions",
          text: "Do you want to delete transactions about this content? <b>This will effect the reporting</b>",
          permission: permission.reports?.delete,
        },
      ],
      onSuccess: () => {
        navigate("/music-album", { replace: true });
      },
    });
  };

  if (!categoryList) return <PageLoading />;

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        item
        xs={12}
        md={6}
        mb={1}
      >
        <Input
          label={"Title"}
          variant="outlined"
          value={title}
          onChange={setTitle}
          warn={(attempted || statusAttempted) && title.length > 0}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        mb={1}
      >
        <Dropdown
          label="Owner"
          value={selectedOwner}
          onChange={setSelectedOwner}
          items={ownerList}
          size="dense"
          disabled={ownerList.length === 0}
          warn={(attempted || statusAttempted) && !selectedOwner}
        />
        <Link
          to={"/artists"}
          target="_blank"
          style={{ float: "right", fontSize: 14, marginTop: 5 }}
        >
          Manage Artists
        </Link>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        mb={1}
      >
        <TagInput
          values={tags}
          onChange={setTags}
          label={"Tags"}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        mb={1}
      >
        <Autocomplete
          multiple
          id="tags-standard"
          options={categoryList}
          value={selectedCategories}
          getOptionLabel={(option) => option?.name}
          style={{ background: "white" }}
          onChange={(event, values) => setSelectedCategories(values)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={"Genres"}
              style={{ background: "white" }}
            />
          )}
        />
        <Link
          target="_blank"
          style={{ float: "right", fontSize: 13 }}
          to={"/music-genres"}
        >
          Manage Genres
        </Link>
      </Grid>


      <Grid
        item
        xs={12}
        md={6}
        mb={1}
      >
        <DateInput
          label={"Release Date"}
          value={releaseDate}
          onChange={setReleaseDate}
        />
      </Grid>


      <Grid
        item
        xs={12}
        md={6}
      >
        <DateInput
          label={"Created At"}
          disabled
          value={moment(detail.created_at)}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
      >
        <DateInput
          label={"Updated At"}
          disabled
          value={moment(detail.updated_at)}
        />
      </Grid>

      {isEdit && (
        <>
          <Divider sx={{ width: "100%", mb: 2, mt: 4 }}>
            <b>Status</b>
          </Divider>

          <Grid
            item
            xs={12}
            md={4}
            mb={1}
          >
            <FormControlLabel
              style={{ marginTop: 10, marginLeft: 10 }}
              control={
                <Switch
                  checked={webStatus}
                  onChange={(e) => handleStatusChange(e, setWebStatus)}
                  name="Website Status"
                />
              }
              label="Website Status"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            mb={1}
          >
            <FormControlLabel
              style={{ marginTop: 10, marginLeft: 10 }}
              control={
                <Switch
                  checked={tvStatus}
                  onChange={(e) => handleStatusChange(e, setTvStatus)}
                  name="TV Status"
                />
              }
              label="TV Status"
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            mb={1}
          >
            <FormControlLabel
              style={{ marginTop: 10, marginLeft: 10 }}
              control={
                <Switch
                  checked={mobileStatus}
                  onChange={(e) => handleStatusChange(e, setMobileStatus)}
                  name="Mobile Status"
                />
              }
              label="Mobile Status"
            />
          </Grid>
        </>
      )}

      <Grid
        item
        xs={12}
        justifyContent={"space-between"}
      >
        {permission?.music.delete && (
          <>
            {isEdit && (
              <Button
                variant="contained"
                color={"error"}
                // sx={{ width: { xs: "100%", sm: "25%" }, float: "right", mb: 2 }}
                onClick={handleDelete}
              >
                Delete
              </Button>
            )}

            <Button
              variant="contained"
              color={"success"}
              sx={{ width: { xs: "100%", sm: "25%" }, float: "right", mb: 2 }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
}

import React, { useState, createRef, memo } from "react";
import Dropzone from "react-dropzone";

import {
  Box,
  Stack,
  Typography,
  ButtonGroup,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { defaultLanguage, languages } from "../../refs";
import ReactHlsPlayer from "react-hls-player";
import { UploadFile } from "@mui/icons-material";

const VideoDropZone = memo(function ({
  value,
  label,
  onChange,
  onDelete,
  onSubmit,
  warn,
  i18n,
  isDetail,
  disabled,
  playerId,
  onLoad,
  accept = "video/mp4",
}) {
  const [selectedLang, setSelectedLang] = useState(defaultLanguage.current);
  const dropzoneRef = createRef();

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  if (i18n) {
    value = value[selectedLang];
  }

  return (
    <Box>
      <Dropzone
        accept={{
          "video/mp4": [],
          "audio/mp3": [],
        }}
        multiple={false}
        ref={dropzoneRef}
        noClick={!isDetail && value ? true : false}
        onDrop={(acceptedFiles) => {
          console.log("@files", acceptedFiles);
          if (acceptedFiles.length === 0) return;

          if (i18n) {
            onChange(acceptedFiles[0], selectedLang);
          } else {
            onChange(acceptedFiles[0]);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              border: "1px solid",
              borderColor: warn ? "#f44336" : "#c4c4c4",
              padding: "10px",
              borderRadius: "10px",
              borderStyle: "dashed",
              minHeight: "150px",
            }}
          >
            {i18n && (
              <FormControl
                sx={{ m: 1, minWidth: 50, ml: 0 }}
                size="small"
                variant="standard"
              >
                <Select
                  labelId="select-lang-label"
                  id="select-lang"
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  {languages.current.map((language) => (
                    <MenuItem
                      key={"video-zone-language-opt-" + language.id}
                      value={language.iso_code}
                    >
                      {language.short_Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <div
              {...getRootProps()}
              style={{ minHeight: "150px" }}
            >
              <input {...getInputProps()} />
              <Typography variant={"subtitle2"}>{label}</Typography>
              {!value && (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    color: "gray",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "130px",
                    cursor: "pointer",
                  }}
                >
                  <UploadFile style={{ width: "40px", height: "40px" }} />
                  <Typography>Upload file</Typography>
                </div>
              )}
              {value && value !== "fake" ? (
                <Box display={"grid"}>
                  {typeof value === "string" && value.includes("m3u8") ? (
                    <ReactHlsPlayer
                      src={value}
                      autoPlay={false}
                      controls={true}
                      width="100%"
                      height="auto"
                      onLoad={(e) => {
                        if (onLoad) onLoad(e);
                      }}
                    />
                  ) : (
                    <video
                      id={playerId}
                      src={typeof value === "string" ? value : URL.createObjectURL(value)}
                      width={"100%"}
                      onLoadedData={(e) => {
                        if (onLoad) onLoad(e);
                      }}
                      controls
                      style={{ objectFit: "contain", background: "#ccc", padding: "10px" }}
                    />
                  )}

                  {value.name && (
                    <Typography
                      style={{ maxWidth: "50%", wordWrap: "break-word" }}
                      variant={"subtitle2"}
                    >
                      {value.name}
                    </Typography>
                  )}

                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                    mt={1}
                    width={"100%"}
                  >
                    {!disabled && (
                      <ButtonGroup
                        style={{ width: "100%" }}
                        disableElevation
                        variant="contained"
                        aria-label="Disabled elevation buttons"
                      >
                        {typeof value === "string" ? (
                          <>
                            {onDelete && (
                              <Button
                                color="error"
                                style={{ width: "100%" }}
                                onClick={onDelete}
                                disabled={disabled ? true : false}
                              >
                                DELETE
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {onSubmit && (
                              <Button
                                color="info"
                                style={{ width: "100%" }}
                                disabled={disabled ? true : false}
                                onClick={onSubmit}
                              >
                                UPLOAD
                              </Button>
                            )}
                            <Button
                              color="error"
                              style={{ width: "100%" }}
                              onClick={() => {
                                if (i18n) {
                                  onChange(null, selectedLang);
                                } else {
                                  onChange(null);
                                }
                              }}
                              disabled={disabled ? true : false}
                            >
                              DELETE
                            </Button>
                          </>
                        )}
                      </ButtonGroup>
                    )}
                  </Stack>
                </Box>
              ) : (
                value === "fake" && (
                  <Box display={"grid"}>
                    <Box sx={{ width: 250, height: 250, background: "gray" }} />
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      aria-label="Disabled elevation buttons"
                    >
                      {isDetail ? (
                        <Button
                          color="info"
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            openDialog();
                          }}
                        >
                          GÜNCELLE
                        </Button>
                      ) : (
                        <Button
                          color="error"
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            if (i18n) {
                              onChange(null, selectedLang);
                            } else {
                              onChange(null);
                            }
                          }}
                        >
                          Sİl
                        </Button>
                      )}
                    </ButtonGroup>
                  </Box>
                )
              )}
            </div>
          </section>
        )}
      </Dropzone>
      {warn && (
        <Typography
          variant="caption"
          color="error"
        >
          This field is mandatory
        </Typography>
      )}
    </Box>
  );
},
isPropsEqual);

function isPropsEqual(prevProps, nextProps) {
  return (
    prevProps.value?.name === nextProps.value?.name &&
    typeof nextProps.value === typeof prevProps.value
  );
}

export default VideoDropZone;

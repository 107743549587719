import { forwardRef } from "react";

// material-ui
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import BackButton from "../BackButton";

// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
  "& .MuiTypography-root": { display: "flex" },
  "& .MuiCardHeader-content": { display: "flex", justifyContent: "space-between" },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //


/*
 * @params {
 *   backText: String
 * }
 */
const MainCard = forwardRef(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      actionButton,
      actionButtons,
      buttonChildren,
      backText,
      onBack,
      ...others
    },
    ref
  ) => {
    return (
      <>
        {backText && (
          <BackButton
            text={backText}
            onClick={onBack}
          />
        )}
        <Card
          ref={ref}
          {...others}
          elevation={0}
          sx={{
            border: border ? "1px solid" : "none",
            borderColor: "rgba(205, 205, 205, 0.596)",
            borderRadius: "15px",
            ":hover": {
              boxShadow: boxShadow ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)" : "inherit",
            },
            ...sx,
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              paddingRight: "3%",
              alignItems: "center",
            }}
          >
            {/* card header and action */}
            {title && (
              <CardHeader
                sx={headerSX}
                title={darkTitle ? <Typography variant="h4">{title}</Typography> : title}
                action={secondary}
              />
            )}
            <div>
              {actionButton && (
                <Button
                  variant="contained"
                  color={actionButton.color}
                  onClick={actionButton.onClick}
                  sx={{ color: "#fff" }}
                >
                  {actionButton.text}
                </Button>
              )}
              {actionButtons &&
                actionButtons.map((button, index) => (
                  <Button
                    key={"action-button-" + index}
                    variant="contained"
                    color={button.color}
                    onClick={button.onClick}
                    sx={{ color: "#fff", ml: 1 }}
                  >
                    {button.text}
                  </Button>
                ))}
              {buttonChildren}
            </div>
            {/* card action */}
          </Box>

          {/* content & header divider */}
          {title && <Divider />}

          {/* card content */}
          {content && (
            <CardContent
              sx={contentSX}
              className={contentClass}
            >
              {children}
            </CardContent>
          )}
          {!content && children}
        </Card>
      </>
    );
  }
);

export default MainCard;

import {
  Button,
  Grid,
  Dialog,
  Slide,
  Alert,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Dropdown from "src/components/Dropdown";
import PageLoading from "src/components/PageLoading";
import SendIcon from "@mui/icons-material/Send";
import HtmlEditor from "src/components/HtmlEditor";
import Input from "src/components/Input";

// import { MainContext } from "src/state/state";
import sendRequest from "src/helpers/service";
import handleError from "src/helpers/errorHandler";
import addFocusEvent from "src/helpers/focusHandler";
import { MainContext } from "src/state/state";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function MailButtonModal({ onClose, onComplete }) {
  const [link, setLink] = useState("");
  const [text, setText] = useState("");

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={onClose}
      // aria-describedby="alert-dialog-slide-description"
    >
      <Alert
        // icon={<SendIcon />}
        severity="info"
        sx={{ alignItems: "center" }}
      >
        <h5 className="mb-0">Add Button</h5>
      </Alert>

      <DialogContent>
        <div style={{ marginBottom: "2%" }}>Please enter button text and link</div>

        <Input
          label={"Label"}
          value={text}
          onChange={setText}
        />
        <br />
        <Input
          label={"Link"}
          value={link}
          onChange={setLink}
        />
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: "red" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          style={{ color: text.length === 0 ? "gray" : "green" }}
          onClick={() => {
            onComplete(text, link);
            onClose();
          }}
          disabled={text.length === 0}
        >
          Create Button
        </Button>
      </DialogActions>
    </Dialog>
  );
}

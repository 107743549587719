import React, { useEffect, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import { Box, Button, Typography } from "@mui/material";
import moment from "moment";
import { isDescendant } from "src/helpers/mixins";

export default function DateRangeModal({ data, setModal, onClose }) {
  const container = useRef();

  useEffect(() => {
    container.current.style.top = data.y + 50 + "px";
    window.addEventListener("click", clickEvent);
    if (data.positionSide === "left") {
      container.current.style.left = data.x + "px";
    } else {
      container.current.style.right = data.x + "px";
    }

    return () => {
      window.removeEventListener("click", clickEvent);
    };
  }, []);

  function clickEvent(event) {
    if (
      !isDescendant(event.target, "date-range-picker-container") &&
      !isDescendant(event.target, "date-range-modal-button")
    ) {
      onClose();
    }
  }

  function onChange(value) {
    data.value = value;
    setModal({
      type: "date-range",
      data,
    });
  }

  return (
    <Box
      id="date-range-picker-container"
      ref={container}
      sx={{
        border: "1px solid #f0f2f6",
        position: "absolute",
        top: 0,
        background: "#fff",
        zIndex: "9999999",
        border: "1px solid lightgray",
        boxShadow: "0 14px 28px rgb(240 242 246), 0 10px 10px rgb(0 0 0 / 22%)",
      }}
    >
      <DateRangePicker
        onChange={(item) => {
          onChange([item.selection]);
        }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={data.value}
        direction="horizontal"
      />
      <Box
        sx={{
          width: "100%",
          height: "50px",
          borderTop: "1px solid #f0f2f6",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          paddingRight: "10px",
          paddingLeft: "10px",
        }}
      >
        <Typography
          variant="overline"
          sx={{ marginRight: "10px" }}
        >
          {moment(data.value[0].startDate).format("DD/MM/YYYY")} -{" "}
          {moment(data.value[0].endDate).format("DD/MM/YYYY")}
        </Typography>
        <Button
          variant="contained"
          color="info"
          style={{ height: "35px" }}
          onClick={() => {
            if (data.onSubmit) data.onSubmit({ value: data.value[0] });

            onClose();
          }}
        >
          {data.onSubmit ? "Apply" : "Close"}
        </Button>
      </Box>
    </Box>
  );
}

// material-ui
import { Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";

// project imports
import NavGroup from "./NavGroup";

import {
  CurrencyExchange,
  Download,
  DvrOutlined,
  GppGood,
  Groups,
  MusicNoteOutlined,
  LibraryMusicOutlined,
  MusicVideo,
  VideoCameraFront,
  Category,
  EmojiSymbols,
  CachedOutlined,
} from "@mui/icons-material";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import { IconDashboard, IconMovie, IconSettingsAutomation } from "@tabler/icons";

import { UserContext } from "src/state/userState";

// ==============================|| SIDEBAR MENU LIST ||============================== //
const MenuList = () => {
  const { contactUsCount, userAccessPages } = useContext(UserContext);
  const [menuItem, setMenuItem] = useState(false);

  useEffect(() => {
    setMenuItem(getMenuItem(userAccessPages));
  }, [contactUsCount]); //eslint-disable-line

  if (!menuItem) return false;

  return menuItem.map((item) => {
    if (!item) return false;

    switch (item.type) {
      case "group":
        return (
          <NavGroup
            key={item.id}
            item={item}
          />
        );
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            color="error"
            align="center"
          >
            Menu Items Error
          </Typography>
        );
    }
  });
};

export default MenuList;

function getMenuItem(pages) {
  const map = {};
  pages.forEach((page) => {
    if (page.write || page.read) map[page.page_id] = true;
  });

  const returnVal = [];
  if (map.dashboard) {
    returnVal.push({
      id: "dashboards",
      title: "Dashboards",
      type: "group",
      breadcrumb: false,
      children: [
        {
          id: "/",
          title: "General Dashboard",
          type: "item",
          icon: IconDashboard,
          url: "/",
        },
      ],
    });
  }

  returnVal.push({
    id: "music",
    title: "Music Content",
    type: "group",
    children: [
      map.music && {
        id: "music",
        title: "Music",
        type: "item",
        roles: [1, 2],
        url: "/music",
        icon: MusicNoteOutlined,
        breadcrumb: false,
      },
      map.music_video && {
        id: "music-video",
        title: "Music Video",
        type: "item",
        url: "/music-video",
        icon: MusicVideo,
        breadcrumb: false,
      },
      map.music && {
        id: "music-album",
        title: "Music Album",
        type: "item",
        url: "/music-album",
        icon: LibraryMusicOutlined,
        breadcrumb: false,
      },
      map.music && {
        id: "music-genres",
        title: "Music Genres",
        type: "item",
        url: "/music-genres",
        icon: Category,
        breadcrumb: false,
      },
    ],
  });

  if (map.movies || map.series) {
    returnVal.push({
      id: "conentManagement",
      title: "Content Management",
      type: "group",
      children: [
        map.movies && {
          id: "movies",
          title: "Movies",
          type: "item",
          url: "/movies",
          icon: IconMovie,
          breadcrumb: false,
        },
        map.series && {
          id: "series",
          title: "TV Series",
          type: "item",
          url: "/series",
          icon: DvrOutlined,
          breadcrumb: false,
        },
        map.series && {
          id: "podcast",
          title: "Podcast",
          type: "item",
          url: "/podcast",
          icon: PodcastsIcon,
          breadcrumb: false,
        },
        {
          id: "featured-content",
          title: "Featured Content",
          type: "item",
          url: "/featured-content",
          icon: EmojiSymbols,
          breadcrumb: false,
        },
      ],
    });
  }

  if (map.film_makers || map.customers || map.admins || map.roles) {
    returnVal.push({
      id: "userManagement",
      title: "User Management",
      type: "group",
      children: [
        map.film_makers && {
          id: "content-creators",
          title: "Content Creators",
          type: "item",
          url: "/content-creators",
          icon: VideoCameraFront,
          breadcrumb: false,
        },
        map.customers && {
          id: "customers",
          title: "Customers",
          type: "item",
          roles: [1, 2],
          url: "/customers",
          icon: CurrencyExchange,
          breadcrumb: false,
        },
        map.admins && {
          id: "admins",
          title: "Admins",
          type: "item",
          url: "/admins",
          icon: GppGood,
          breadcrumb: false,
        },
        map.roles && {
          id: "roles",
          title: "Roles",
          type: "item",
          roles: [1, 2],
          url: "/roles",
          icon: Groups,
          breadcrumb: false,
        },
      ],
    });
  }

  if (map.settings || map.translations || map.faq || map.configurations || map.subscription) {
    returnVal.push({
      id: "applicationSettings",
      title: "Settings",
      type: "group",
      children: [
        map.settings && {
          id: "content-settings",
          title: "Content Settings",
          type: "item",
          url: "/content-settings",
          icon: IconSettingsAutomation,
          breadcrumb: false,
        },
        map.settings && {
          id: "cache-settings",
          title: "Cache",
          type: "item",
          url: "/content-cache",
          icon: CachedOutlined,
          breadcrumb: false,
          children: [],
        },
      ],
    });
  }

  returnVal.push({
    id: "transactions",
    title: "Transactions",
    type: "group",
    children: [
      true && {
        id: "sony-ingestion",
        title: "Sony Ingestion",
        type: "item",
        roles: [1, 2],
        url: "/sony-ingestion",
        icon: Download,
        breadcrumb: false,
      },
      // map.settings && {
      //   id: "general-settings",
      //   title: "General",
      //   type: "collapse",
      //   roles: [1, 2],
      //   icon: IconSettings,
      //   breadcrumb: false,
      //   children: [
      //   ],
      // },
    ],
  });

  return returnVal;
}

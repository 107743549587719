import React, { useContext, useEffect, useState } from "react";
import { Grid, Button, Typography, Divider, CircularProgress } from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";

import { MainContext } from "../../../state/state";
import VideoDropZone from "../../../components/VideoDropZone";
import ProgressModal from "src/modal/ProgressModal";
import PageLoading from "src/components/PageLoading";

import sendRequest from "../../../helpers/service";
import { toast } from "react-toastify";
import handleError from "src/helpers/errorHandler";
import addFocusEvent from "src/helpers/focusHandler";

import { CheckCircle } from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import openDeleteModal from "src/helpers/deleteHandler";

import uploadMultipartFile from "src/helpers/fileUploader";

export default function VideoTab({ permission, detail, setDetail }) {
  const { setModal, setLoading } = useContext(MainContext);

  const [isFocused, setIsFocused] = useState(false);

  const [movieBitrates, setMovieBitrates] = useState(false);
  const [trailerBitrates, setTrailerBitrates] = useState(false);
  const [progressLoading, setProgressLoading] = useState(false);

  const [music, setMusic] = useState(detail.original_music);

  const [trailer, setTrailer] = useState(
    detail.original_trailer
      ? process.env.REACT_APP_AWS_S3_MUSIC_URL + detail.original_trailer
      : null
  );

  useEffect(() => {
    if (isFocused && progressLoading !== false) {
      (async () => {
        const serieBitrates = await sendRequest.get({ url: "settings/bitrates/single_content" });
        setMovieBitrates(serieBitrates.data);

        const trailerBitrates = await sendRequest.get({ url: "settings/bitrates/trailer" });
        setTrailerBitrates(trailerBitrates.data);
      })();
    }
  }, [isFocused]); //eslint-disable-line

  useEffect(() => {
    const unsubscribe = addFocusEvent({
      onFocus: () => setIsFocused(true),
      onBlur: () => setIsFocused(false),
    });

    (async () => {
      const movieBitrates = await sendRequest.get({ url: "settings/bitrates/single_content" });
      setMovieBitrates(movieBitrates.data);

      const trailerBitrates = await sendRequest.get({ url: "settings/bitrates/trailer" });
      setTrailerBitrates(trailerBitrates.data);
    })();

    return unsubscribe;
  }, []);

  const handleSubmit = (contentType) => {
    if (
      (detail.original_music && typeof music === "object") ||
      (detail.original_trailer && typeof trailer === "object")
    ) {
      setModal({
        title: "Saving New Content",
        message: "Previous content will be <b>deleted completely</b>. Are you sure to continue?",
        type: "info",
        cancelButton: {
          text: "Cancel",
        },
        confirmButton: {
          text: "Continue",
          onClick: () => submitAction(contentType),
        },
      });
    } else {
      submitAction(contentType);
    }
  };

  const submitAction = async (contentType) => {
    if ((contentType === "music" && !music) || (contentType === "trailer" && !trailer)) {
      toast("You need to select " + contentType, {
        type: "error",
      });
      return;
    }

    setProgressLoading({ preparing: true });
    const musicPlayer = document.getElementById("music-player");
    const trailerPlayer = document.getElementById("trailer-player");

    try {
      const res = await uploadMultipartFile({
        file: contentType === "music" ? music : trailer,
        contentId: detail.id,
        apiPrefix: "music",
        contentType,
        onProgress: setProgressLoading,
        duration: Math.round(musicPlayer?.duration || 0),
        // videoDimensions: videoPlayer.videoWidth + "x" + videoPlayer.videoHeight,
        // trailerDimensions: trailerPlayer.videoWidth + "x" + trailerPlayer.videoHeight,
      });

      if (contentType === "music") {
        setMusic(res.data.url);
      } else {
        setTrailer(res.data.url);
      }

      setDetail((detail) => {
        if (contentType === "trailer") {
          detail.original_trailer = res.data.original_trailer;
        } else {
          detail.original_music = res.data.original_music;
        }

        return detail;
      });

      toast("Changes Saved.", {
        type: "success",
      });
      setProgressLoading(false);
    } catch (error) {
      console.log("Upload Music Error: ", error);
      handleError(error);
      setProgressLoading(false);
    }
  };

  function startConvertProgress(type) {
    if (type === "music" && detail.music) {
      setModal({
        title: "Saving Music Content",
        message: "Previous content will be <b>deleted completely</b>. Are you sure to continue?",
        type: "info",
        cancelButton: {
          text: "Cancel",
        },
        confirmButton: {
          text: "Continue",
          onClick: () => convertProgressAction(type),
        },
      });
      return;
    } else if (type === "trailer" && detail.trailer) {
      setModal({
        title: "Start Trailer Convert",
        message: "Previous content will be <b>deleted completely</b>. Are you sure to continue?",
        type: "info",
        cancelButton: {
          text: "Cancel",
        },
        confirmButton: {
          text: "Continue",
          onClick: () => convertProgressAction(type),
        },
      });
      return;
    }

    convertProgressAction(type);
  }

  function convertProgressAction(type) {
    setLoading(true);
    sendRequest
      .post({
        url: "music/start-conversion",
        data: {
          content_type: type,
          content_id: detail.id,
          media_type_id: detail.media_type,
        },
      })
      .then((res) => {
        setDetail((detail) => {
          detail.convert_status = "in_queue";
          detail.convert_queue = res.data || 0;
          return detail;
        });

        toast("Started To Convert Streaming. This can take few minutes", {
          theme: "colored",
          type: "success",
        });
        setLoading(false);
      })
      .catch((err) => {
        handleError(err);
      });
  }

  const handleDelete = async (type) => {
    openDeleteModal({
      modals: [
        {
          title: `Delete ${type === "trailer" ? "Trailer" : "Music"}`,
          message: `Are you sure to delete this ${type}? <br/> <b>This cannot be reverted and content will become draft</b>`,
          confirmButton: {
            text: "Delete",
            color: "red",
          },
          cancelButton: {
            text: "Cancel",
            color: "black",
          },
        },
      ],
      apiUrl: `/music/delete-content/${type}/${detail.id}`,
    }).then(() => {
      setDetail((detail) => {
        if (type === "trailer") {
          detail.original_trailer = null;
          detail.trailer = null;
          detail.trailer_convert_status = false;
          detail.trailer_convert_data = null;
          setTrailer(null);
        } else {
          detail.original_music = null;
          detail.music = null;
          detail.music_convert_status = false;
          detail.music_convert_data = null;
          setMusic(null);
        }

        detail.tv_status = false;
        detail.web_status = false;
        detail.mobile_status = false;

        return detail;
      });
      if (detail.tv_status || detail.web_status || detail.mobile_status) {
        toast("Content status become traft for all platforms", {
          type: "warning",
          theme: "colored",
        });
      }
    });
  };

  if (!movieBitrates || !trailerBitrates) return <PageLoading />;

  return (
    <Grid container>
      {progressLoading !== false && <ProgressModal data={progressLoading} />}
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          mb={3}
        >
          {progressLoading === false && (
            <VideoDropZone
              playerId="music-player"
              value={music}
              label={"Content(MP3)"}
              onChange={setMusic}
              accept="audio/mp3"
              disabled={
                !permission?.music?.write ||
                ["in_queue", "in_progress"].includes(detail.convert_status)
              }
              onSubmit={() => handleSubmit("music")}
              onDelete={() => handleDelete("music")}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          pl={4}
        >
          <Typography fontWeight={700}>Quality Options for the Music</Typography>
          {movieBitrates.map((bitrate) => (
            <Typography key={"movie-bitrate-" + bitrate.id}>
              • {bitrate.name} - {bitrate.rate}
            </Typography>
          ))}

          {detail.convert_status === "in_queue" && (
            <Grid
              style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
              mt={3}
            >
              <CircularProgress
                color="info"
                size={30}
                value={0}
                style={{ marginRight: "3%" }}
              />
              &nbsp; Converting request in queue... There is {detail.convert_queue} content in queue
            </Grid>
          )}
          {detail.convert_status === "in_progress" && (
            <Grid
              style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
              mt={3}
            >
              <CircularProgress
                color="info"
                size={30}
                value={0}
              />
              &nbsp; Converting music in progress
            </Grid>
          )}
          {/* --- Succes Convert Message --- */}
          {detail.convert_status === "done" && (
            <Grid
              mt={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <CheckCircle style={{ color: "green", fontSize: "1.4rem" }} />
              Successfully Converted in{" "}
              {moment(detail.convert_data?.updated_at).format("DD/MM/YYYY HH:MM")}
            </Grid>
          )}

          {/* --- Convert Error Message --- */}
          {detail.convert_status === "error" && (
            <Grid
              mt={2}
              style={{ display: "flex", alignItems: "center" }}
            >
              <ErrorIcon style={{ color: "red", fontSize: "1.4rem" }} />
              <div
                onClick={() => {
                  toast(detail.convert_data?.error, {
                    type: "error",
                    theme: "colored",
                  });
                }}
                style={{ textDecoration: "underline" }}
              >
                Unexpected error occured please try again
              </div>
            </Grid>
          )}
          {!["in_progress", "in_queue"].includes(detail.convert_status) && (
            <Grid mt={2}>
              {permission?.settings?.write && (
                <Link
                  to={"/content-settings?tab=0"}
                  target="_blank"
                >
                  <Button
                    variant="contained"
                    color="info"
                  >
                    Quality Options
                  </Button>
                </Link>
              )}

              {permission?.music?.write && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: 2 }}
                  disabled={!detail.original_music}
                  onClick={() => startConvertProgress("music")}
                >
                  {detail.convert_data ? "Convert Again" : "Convert To Streaming"}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
        <Grid></Grid>
      </Grid>
    </Grid>
  );
}

import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import LinearProgress from "@mui/material/LinearProgress";

import { UploadFile } from "@mui/icons-material";
import { Alert, Box, Grid, Typography } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function ProgressModal({ data, onClose }) {
  if (!data) return false;

  let text = "";
  let progress;
  if (data.preparing) {
    text = "Preparing...";
  } else if (data.progress >= 100) {
    text = "Proccessing...";
  } else {
    text = "Please wait, uploading content...";
    progress = `${data.loaded} / ${data.total}`;
  }
  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <Alert
        icon={<UploadFile fontSize="inherit" />}
        severity="info"
        sx={{ alignItems: "center" }}
      >
        <h5 className="mb-0">{data.progress < 100 ? "Uploading Content" : "Preparing Content"}</h5>
      </Alert>

      <DialogContent>
        <DialogContentText textAlign={"center"} id="alert-dialog-slide-description">{text}</DialogContentText>

        <Grid
          style={{ display: "flex" }}
          justifyContent={"center"}
          alignItems={"center"}
          mt={2}
        >
          <LinearProgress
            style={{ width: "100%", height: 10, borderRadius: 5 }}
            color="info"
            variant={data.progress > 0 && data.progress < 100 ? "determinate" : "indeterminate"}
            value={typeof data.progress === "number" ? data.progress : 0}
          />
          {!data.preparing && (
            <Box
              sx={{ minWidth: 35 }}
              ml={1}
            >
              <Typography
                variant="body2"
                color="text.secondary"
              >{`${Math.round(data.progress)}%`}</Typography>
            </Box>
          )}
        </Grid>
        {progress && (
          <DialogContentText
            fontSize={13}
            textAlign={"center"}
          >
            {progress}
          </DialogContentText>
        )}
        <Grid mt={2}>
          <Typography
            fontWeight={"bold"}
            textAlign={"center"}
          >
            Please do not close the page untill process done!
          </Typography>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

import { toast } from "react-toastify";
import handleError from "./errorHandler";
import sendRequest from "./service";

/*
 * @params {
 *   apiUrl: String
 * }
 */
export default function openDeleteModal(params, index = 0) {
  return new Promise((resolve, reject) => {
    if (params.modals[index]) {
      resolve(showModal(params, index));
    }
  });
}

function showModal(params, index) {
  return new Promise((resolve, reject) => {
    const modal = params.modals[index];
    window.setModal({
      title: modal.title,
      message: modal.message,
      type: "warning",
      cancelButton: {
        text: modal.cancelButton.text,
        color: modal.cancelButton.color,
        onClick: () => {
          if (modal.type === "parameter") {
            if (!params.apiParams) params.apiParams = {};

            params.apiParams[modal.parameterKey] = modal.cancelButton.value;
            console.log("@here", params, index);
            if (params.modals[index + 1]) {
              setTimeout(() => {
                console.log("openinng next", params, index + 1);
                resolve(openDeleteModal(params, index + 1));
              }, 0);
            } else if (params.validateModal) {
              setTimeout(() => {
                resolve(openValidationModal(params));
              }, 0);
            }
          } else {
            reject();
          }
        },
      },
      confirmButton: {
        text: modal.confirmButton.text,
        color: modal.confirmButton.color,
        onClick: () => {
          if (modal.type === "parameter") {
            if (!params.apiParams) params.apiParams = {};

            params.apiParams[modal.parameterKey] = modal.confirmButton.value;
          }

          if (params.modals[index + 1]) {
            setTimeout(() => {
              resolve(openDeleteModal(params, index + 1));
            }, 0);
            return;
          }

          if (params.validateModal) {
            setTimeout(() => {
              resolve(openValidationModal(params));
            }, 0);
          } else {
            window.setLoading(true);
            sendRequest
              .delete({ url: params.apiUrl })
              .then(() => {
                toast(params.successText || "Record deleted successfully", {
                  type: "success",
                  theme: "colored",
                });
                resolve();
              })
              .catch((error) => {
                handleError(error);
                reject();
              })
              .finally(() => window.setLoading(false));
          }
        },
      },
    });
  });
}

function openValidationModal(params) {
  return new Promise((resolve, reject) => {
    const validationText = params.validationText || "CONFIRM";
    
    window.setModal({
      title: "Validate Delete Request",
      message: `Please enter '<b>${validationText}</b>' to delete this record'`,
      type: "validation",
      validationText,
      confirmButton: {
        text: "Confirm",
        color: "red",
        onClick: () => {
          window.setLoading(true);
          sendRequest
            .delete({
              url: params.apiUrl,
              data: params.apiParams,
            })
            .then(() => {
              resolve();
              toast(params.successText || "Record deleted successfully", {
                type: "success",
                theme: "colored",
              });
            })
            .catch((error) => {
              handleError(error);
              reject();
            })
            .finally(() => window.setLoading(false));
        },
      },
      cancelButton: {
        text: "Cancel",
        color: "black",
        onClick: reject,
      },
    });
  });
}

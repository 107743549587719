import React, { useReducer } from "react";
import { mainReducer } from "./reducer";

const defaultState = {
  loading: false,
  snackbar: false,
  breadcrumb: false,
  modal: false,
  statusEnum: [],
  projectCreateStepEnum: [],
  paymentTermEnum: [],
  compnayTypeEnum: [],
};

export const MainContext = React.createContext({
  ...defaultState,
});

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, defaultState);

  const setLoading = (payload) => {
    dispatch({ type: "SET_LOADING", payload });
  };

  const setSnackbar = (payload) => {
    dispatch({ type: "SET_SNACK", payload });
  };

  const setBreadcrumb = (payload) => {
    dispatch({ type: "SET_BREADCRUMB", payload });
  };

  const setProjectCreateStepEnum = (payload) => {
    dispatch({ type: "SET_PROJECT_CREATE_STEP_ENUM", payload });
  };

  const setStatusEnum = (payload) => {
    dispatch({ type: "SET_STATUS_ENUM", payload });
  };

  const setPaymentTermEnum = (payload) => {
    dispatch({ type: "SET_PAYMENT_TERM_ENUM", payload });
  };

  const setCompanyTypeEnum = (payload) => {
    dispatch({ type: "SET_COMPANY_TYPE_ENUM", payload });
  };

  const setModal = (payload) => {
    dispatch({ type: "SET_MODAL", payload });
  };

  const value = {
    loading: state.loading,
    snackbar: state.snackbar,
    breadcrumb: state.breadcrumb,
    statusEnum: state.statusEnum,
    projectCreateStepEnum: state.projectCreateStepEnum,
    paymentTermEnum: state.paymentTermEnum,
    compnayTypeEnum: state.compnayTypeEnum,
    modal: state.modal,

    setLoading,
    setSnackbar,
    setBreadcrumb,
    setStatusEnum,
    setProjectCreateStepEnum,
    setPaymentTermEnum,
    setCompanyTypeEnum,
    setModal,
  };

  return <MainContext.Provider  value={value}>{children}</MainContext.Provider>;
};

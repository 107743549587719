import { Backdrop, CircularProgress } from "@mui/material";
import React, { useContext } from "react";
import { MainContext } from "../../state/state";

export default function Loading({ page }) {
  const { loading } = useContext(MainContext);

  if (!page && !loading) return null;
  
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: 99999999 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

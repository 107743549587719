import {
  Button,
  Grid,
  Dialog,
  Slide,
  Alert,
  DialogActions,
  DialogContent,
  Typography,
  Checkbox,
  FormControlLabel,
  Divider,
  DialogContentText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

// import { MainContext } from "src/state/state";
import sendRequest from "src/helpers/service";
import handleError from "src/helpers/errorHandler";
import { MainContext } from "src/state/state";
import { IconTrash } from "@tabler/icons";
import Input from "src/components/Input";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  );
});

export default function DeleteModal({ data, onClose }) {
  const { setLoading } = useContext(MainContext);

  const [attempted, setAttempted] = useState(false);
  const [optionValues, setOptionValues] = useState({});

  const [validationText, setValidationText] = useState("");

  async function handleSend() {
    try {
      setAttempted(true);
      if (data.validation && validationText !== "permanently delete") return;

      setAttempted(false);
      setLoading(true);
      const res = await sendRequest.delete({ url: data.apiUrl, data: optionValues });

      if (data.successText) {
        toast(data.successText, {
          type: "success",
          theme: "colored",
        });
      }

      if (data.onSuccess) data.onSuccess(res.data);

      onClose();
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={onClose}
    >
      <Alert
        icon={<IconTrash />}
        severity="error"
        sx={{ alignItems: "center" }}
      >
        <h5 className="mb-0">{data.title}</h5>
      </Alert>
      <DialogContent>
        <DialogContentText>
          <span dangerouslySetInnerHTML={{ __html: data.message }}></span>
        </DialogContentText>

        {data.warnings?.map((warning, index) => {
          return (
            <Typography
              key={"delete-modal-warning-" + index}
              style={{ marginTop: "2%", display: "flex", alignItems: "center" }}
              fontSize={14}
              fontWeight={500}
              color={"darkorange"}
            >
              <WarningAmberIcon style={{ fontSize: "17px", marginRight: 3 }} />
              <span dangerouslySetInnerHTML={{ __html: warning }} />
            </Typography>
          );
        })}

        {data.options && (
          <>
            <Divider style={{ margin: "2% 0" }} />
            <Typography
              fontSize={13}
              color={"black"}
            >
              These informations can be deleted with the record. Please select options to delete
              that related with the record
            </Typography>
            {data.options?.map((option, index) => {
              if (!option.permission) return false;

              return (
                <Grid
                  key={"option-" + option.keyword}
                  item
                  md={2}
                  xs={6}
                >
                  <FormControlLabel
                    sx={{ mb: 0 }}
                    control={
                      <>
                        <Checkbox
                          checked={optionValues[option.keyword]}
                          onChange={(e) => {
                            setOptionValues((values) => {
                              values[option.keyword] = e.target.checked;
                              return values;
                            });
                          }}
                        />
                        <span
                          style={{ fontSize: 14 }}
                          dangerouslySetInnerHTML={{ __html: option.text }}
                        />
                      </>
                    }
                    // dangerouslySetInnerHTML={{ __html: option.text }}
                  />
                  <Typography
                    fontSize={13}
                    color={"rgb(255, 100, 100)"}
                    mt={-1}
                  >
                    <span dangerouslySetInnerHTML={{ __html: option.warning }} />
                  </Typography>
                  {data.options.length - 1 !== index && <Divider style={{ marginTop: "2%" }} />}
                </Grid>
              );
            })}
          </>
        )}
        {data.validation && (
          <>
            <Divider style={{ margin: "2% 0" }} />
            <Typography
              style={{ marginBottom: "1%" }}
              fontSize={14}
            >
              Enter "<b>permanently delete</b>" to delete the record
            </Typography>
            <Input
              size="small"
              value={validationText}
              onChange={setValidationText}
              warn={attempted && "permanently delete" !== validationText}
              warning="Please enter validation text to delete record"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Typography
          style={{ position: "absolute", bottom: 0, left: "2%" }}
          color={"gray"}
          fontSize={13}
          mb={2}
        ></Typography>
        <Button
          style={{ color: "black" }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          // style={{ color: "red" }}
          color="error"
          onClick={handleSend}
          // disabled={data.validation && validationText !== data.validationText}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useContext } from "react";
import { MainContext } from "src/state/state";

import MailModal from "./MailModal";
import DialogModal from "src/components/Modal";
import DateRangeModal from "./DateRangeModal";
import DeleteModal from "./DeleteModal";

export default function Modal() {
  const { modal, setModal } = useContext(MainContext);

  if (!modal) {
    return false;
  }

  function onClose() {
    setModal(false);
  }

  switch (modal.type) {
    case "email":
      return (
        <MailModal
          data={modal}
          onClose={onClose}
        />
      );
    case "date-range":
      return (
        <DateRangeModal
          onClose={onClose}
          setModal={setModal}
          data={modal.data}
        />
      );
    case "delete":
      return (
        <DeleteModal
          onClose={onClose}
          data={modal}
        />
      );
    default:
      return (
        <DialogModal
          modal={modal}
          onClose={onClose}
        />
      );
  }
}

import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";

export default function CredentialsTab({ detail }) {
  const [credentials, setCredentials] = useState(false);

  const nameMap = {
    title: "Title",
    subtitle: "Subtitle",
    display_title: "Display Title",
    display_artist_name: "Artist Name",
    provider: "Provider",
    provider_year: "Provider Year",
    MainArtist: "Main Artist",
  };

  useEffect(() => {
    if (detail.credentials) {
      const data = JSON.parse(detail.credentials);
      const newData = [];
      for (const key in data) {
        newData.push({
          name: nameMap[key] || key,
          value: data[key],
        });
      }
      setCredentials(newData);
    }
  }, []);

  const handleSubmit = async () => {
    // setLoading(true);
    // try {
    //   const img = await getMeta(newImage);
    //   const res = await sendRequest.post({
    //     url: "/music/upload-image",
    //     data: formData,
    //   });
    //   toast("Changes Saved.", {
    //     type: "success",
    //   });
    //   setNewImage(false);
    //   let newImages = images ? [...images] : [];
    //   newImages.push(res.data);
    //   setImages(newImages);
    //   setDetail((detail) => {
    //     detail.images = newImage;
    //     return detail;
    //   });
    //   setLoading(false);
    // } catch (error) {
    //   console.log("error on music image upload: ", error);
    //   handleError(error);
    // }
  };

  function handleDelete(imageId) {
    // openDeleteModal({
    //   apiUrl: `music/delete-image/${detail.id}/${imageId}`,
    //   successText: "Image deleted successfully",
    //   modals: [
    //     {
    //       title: "Delete Image",
    //       message: `Are you sure to delete this image?`,
    //       cancelButton: {
    //         text: "Cancel",
    //       },
    //       confirmButton: {
    //         text: "Delete",
    //       },
    //     },
    //   ],
    // }).then((res) => {
    //   setImages((images) => {
    //     return images.filter((image) => image.id !== imageId);
    //   });
    // });
  }

  return (
    <div>
      {credentials &&
        credentials?.map((credential) => {
          return (
            <Grid
              container
              spacing={2}
              mb={2}
              className="border-bottom pb-3"
            >
              <Grid
                item
                md={3}
                fontSize={13}
                fontWeight={"Bold"}
              >
                {credential.name}
              </Grid>
              <Grid
                item
                md={7}
                fontSize={13}
              >
                {typeof credential.value === "string" && (credential.value || "--")}
                {Array.isArray(credential.value) &&
                  credential.value.map((value) => {
                    return <p>{value || "--"}</p>;
                  })}
              </Grid>
            </Grid>
          );
        })}
    </div>
  );
}

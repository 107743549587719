import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab, Box, Alert } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

import MainCard from "../../../components/MianCard";
import GeneralTab from "./GeneralTab";
import VideoTab from "./VideoTab";
import ImageTab from "./ImageTab";

import Loading from "../../../components/Loading";
import sendRequest from "../../../helpers/service";
import handleError from "src/helpers/errorHandler";

export default function MoviePage({ permission }) {
  const params = useParams();
  const navigate = useNavigate();
  const isEdit = !!params?.id;

  const [tab, setTab] = useState(getInitialTab());
  const [detail, setDetail] = useState(false);

  useEffect(() => {
    if (isEdit) {
      sendRequest
        .get({ url: "movies/detail/" + params?.id })
        .then((res) => {
          setDetail(res.data);
        })
        .catch((err) => {
          navigate(-1);
          handleError(err);
        });
    }
  }, []); //eslint-disable-line

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    setUrlParameter(newValue);
  };

  function getInitialTab() {
    const params = new URLSearchParams(window.location.search);

    if (!params.get("tab")) {
      setUrlParameter(0);
      return 0;
    }

    return parseInt(params.get("tab"));
  }

  function setUrlParameter(value) {
    let url = window.location.href;
    var pattern = new RegExp("\\b(tab=).*?(&|#|$)");
    if (url.search(pattern) >= 0) {
      url = url.replace(pattern, "$1" + value + "$2");
    } else {
      url = `${url.replace(/[?#]$/, "")}${url.indexOf("?") > 0 ? "&" : "?"}tab=${value}`;
    }
    window.history.replaceState({}, "", url);
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {children}
      </div>
    );
  }

  if (isEdit && !detail) return <Loading page />;

  return (
    <MainCard
      backText="All Movies"
      onBack={() => navigate(-1)}
      title={isEdit ? "Update Movie" : "Create Movie"}
      border={true}
      boxShadow
      sx={{
        borderRadius: "15px",
        marginBottom: "2rem",
      }}
    >
      <Alert severity="info">
        <span>
          Each tab should save seperately! -{" "}
          <strong>Othervise changes will are not going to be saved.</strong>
        </span>
      </Alert>
      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 3 }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="general info tab"
        >
          <Tab
            label="General Info"
            {...a11yProps(0)}
          />
          <Tab
            label="Video Contents"
            disabled={!isEdit}
            {...a11yProps(1)}
          />
          <Tab
            label="Images"
            disabled={!isEdit}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Container
        maxWidth={false}
        sx={{ width: "100%" }}
      >
        <TabPanel
          value={tab}
          index={0}
        >
          {tab === 0 && (
            <GeneralTab
              permission={permission}
              isEdit={isEdit}
              detail={detail}
              id={params.id}
              navigate={navigate}
            />
          )}
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
        >
          {tab === 1 && (
            <VideoTab
              id={params?.id}
              navigate={navigate}
              permission={permission}
              detail={detail}
              setDetail={setDetail}
            />
          )}
        </TabPanel>
        <TabPanel
          value={tab}
          index={2}
        >
          {tab === 2 && (
            <ImageTab
              detail={detail}
              setDetail={setDetail}
              permission={permission}
            />
          )}
        </TabPanel>
      </Container>
    </MainCard>
  );
}

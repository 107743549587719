export function slugifyText(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  } 

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export function getTextFromHTML(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
}

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const isDescendant = (el, parentId) => {
  if (el instanceof Element && el.dataset.popup) return true;

  let isChild = false;

  if (el.id === parentId) {
    //is this the element itself?
    isChild = true;
  }

  while ((el = el.parentNode)) {
    // eslint-disable-next-line
    if (el.id == parentId) {
      //eslint-disable-line
      isChild = true;
    }
  }

  return isChild;
};

/*
  Adds javascript file dynamicly
*/
export function addScript(src, attributes = []) {
  return new Promise((resolve, reject) => {
    var s = document.createElement("script"),
      r = false,
      t;

    s.type = "text/javascript";
    s.src = src;
    attributes.forEach((attribute) => {
      s.setAttribute(attribute.key, attribute.value);
    });
    s.setAttribute("type", "text/javascript");

    s.onload = s.onreadystatechange = function () {
      // eslint-disable-next-line
      if (!r && (!this.readyState || this.readyState == "complete")) {
        //eslint-disable-line
        r = true;
        setTimeout(() => {
          resolve(true);
        }, 200);
      }
    };
    s.onerror = s.onabort = reject;
    t = document.getElementsByTagName("script")[0];

    setTimeout(() => {
      t.parentNode.insertBefore(s, t);
    }, 0);
  });
}

export function getqueryParameters(url) {
  if (!url) return {};

  var regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;

  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }
  return params;
}

export function getInputDate(date, excludeHours) {
  //It converts to iso string without calculating GMT time
  if (excludeHours)
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}`;
  else
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
      "0" + date.getDate()
    ).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}:${(
      "0" + date.getSeconds()
    ).slice(-2)}.${("00" + date.getMilliseconds()).slice(-3)}`;
}

import React, { useState, useEffect } from "react";
import Navigation from "../src/router";
import sendRequest from "./helpers/service";
import { defaultLanguage, languages } from "./refs";
import handleError from "./helpers/errorHandler";
import PageLoading from "./components/PageLoading";

export default function Main() {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        // const langResponse = await sendRequest.get({ url: "language-list" });
        const langResponse = { data: [{ iso_code: "en", name: "English" }] };
        languages.current = langResponse.data.map((language) => {
          language.short_name = language.iso_code.toUpperCase();
          return language;
        });
        defaultLanguage.current = "en";
      } catch (error) {
        handleError(error);
        console.log("panel role pages error:", error);
      }
      setReady(true);
    })();
  }, []); //eslint-disable-line

  if (!ready) return <PageLoading />;

  return <Navigation />;
}

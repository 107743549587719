import { ArrowBackSharp } from "@mui/icons-material";
import { Typography } from "@mui/material";

import c from "./backButton.module.css";

export default function BackButton({ text, onClick }) {
  return (
    <div
      className={c.container.toString() + " d-flex mb-3 align-items-center"}
      onClick={onClick}
    >
      <ArrowBackSharp />
      <Typography
        fontSize={18}
        fontWeight={500}
      >
        {text}
      </Typography>
    </div>
  );
}

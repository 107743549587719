import React, { useState, createRef } from "react";
import Dropzone from "react-dropzone";

import {
  Box,
  Stack,
  Typography,
  ButtonGroup,
  Button,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

export default function ImageDropZone({
  documentId,
  value,
  label,
  onChange,
  warn,
  i18n,
  isDetail,
  onUpload,
  onDelete,
}) {
  const [selectedLang, setSelectedLang] = useState("tr");
  const dropzoneRef = createRef();
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };
  if (i18n) {
    value = value[selectedLang];
  }

  const isLink = value && typeof value === "string";
  const isNewImage = value && typeof value !== "string";

  return (
    <Box>
      <Dropzone
        accept={{
          "image/*": [],
        }}
        multiple={false}
        ref={dropzoneRef}
        noClick={!isDetail && value ? true : false}
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length === 0) return;

          if (i18n) {
            onChange(acceptedFiles[0], selectedLang);
          } else {
            onChange(acceptedFiles[0]);
          }
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              border: "1px solid",
              borderColor: warn ? "#f44336" : "#c4c4c4",
              padding: "10px",
              borderRadius: "10px",
              borderStyle: "dashed",
              minHeight: "150px",
            }}
          >
            {i18n && (
              <FormControl
                sx={{ m: 1, minWidth: 50, ml: 0 }}
                size="small"
                variant="standard"
              >
                <Select
                  labelId="select-lang-label"
                  id="select-lang"
                  value={selectedLang}
                  onChange={(e) => setSelectedLang(e.target.value)}
                >
                  <MenuItem value={"tr"}>TR</MenuItem>
                  <MenuItem value={"en"}>EN</MenuItem>
                </Select>
              </FormControl>
            )}
            <div
              {...getRootProps()}
              style={{ minHeight: "150px" }}
            >
              <input {...getInputProps()} />
              <Typography variant={"subtitle2"}>{label}</Typography>
              {value && (
                <Box display={"grid"}>
                  <img
                    src={typeof value === "string" ? value : URL.createObjectURL(value)}
                    width={"100%"}
                    height={"auto"}
                    style={{ objectFit: "contain", background: "#ccc", padding: "10px" }}
                  />
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    spacing={2}
                    mt={1}
                    width={"100%"}
                  >
                    <Typography
                      style={{ maxWidth: "89%", overflow: "hidden", textOverflow: "ellipsis" }}
                      variant={"subtitle2"}
                    >
                      {value.name}
                    </Typography>
                    <ButtonGroup
                      disableElevation
                      variant="contained"
                      aria-label="Disabled elevation buttons"
                    >
                      {isNewImage && onUpload && (
                        <Button
                          color="info"
                          onClickCapture={onUpload}
                        >
                          Upload
                        </Button>
                      )}
                      {documentId && (
                        <Button
                          color="info"
                          disabled={typeof value === "string"}
                          onClickCapture={(e) => {
                            e.stopPropagation();
                            openDialog();
                          }}
                        >
                          Update
                        </Button>
                      )}
                      <Button
                        color="error"
                        onClickCapture={(e) => {
                          e.stopPropagation();
                          if (documentId) {
                            onDelete(documentId);
                          } else if (i18n) {
                            onChange(null, selectedLang);
                          } else {
                            onChange(null);
                          }
                        }}
                      >
                        Delete
                      </Button>
                    </ButtonGroup>
                  </Stack>
                </Box>
              )}
            </div>
          </section>
        )}
      </Dropzone>
      {warn && (
        <Typography
          variant="caption"
          color="error"
        >
          This field is mandatory
        </Typography>
      )}
    </Box>
  );
}

import React, { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { MainContext } from "../../../state/state";

import sendRequest from "../../../helpers/service";
import { toast } from "react-toastify";
import ImageDropZone from "../../../components/ImageDropZone";
import handleError from "src/helpers/errorHandler";
import openDeleteModal from "src/helpers/deleteHandler";

export default function ImageTab({ permission, detail, setDetail }) {
  const { setLoading } = useContext(MainContext);
  console.log("@detail", detail.images);

  const [images, setImages] = useState(detail.images);
  //if user wants to publish it, we need to light all reuqired fields with this state

  const [newImage, setNewImage] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const img = await getMeta(newImage);

      const formData = new FormData();
      formData.append("content_id", detail.id);
      formData.append("image", newImage);
      formData.append("thumbnail", newImage);
      formData.append("width", img.naturalWidth);
      formData.append("height", img.naturalHeight);
      formData.append("extension", newImage.type);

      const res = await sendRequest.post({
        url: "/movies/update-image",
        data: formData,
      });
      toast("Changes Saved.", {
        type: "success",
      });

      // if (res.data.image) {
      //   setHorizontalImage(process.env.REACT_APP_AWS_S3_MOVIES_URL + res.data.image);
      // }

      setNewImage(false);
      let newImages = [...images];
      newImages.push(res.data);
      setImages(newImages);
      setDetail((detail) => {
        detail.images = newImage;
        return detail;
      });

      setLoading(false);
    } catch (error) {
      console.log("error on movie image upload: ", error);
      handleError(error);
    }
  };

  function handleDelete(imageId) {
    openDeleteModal({
      apiUrl: `movies/delete-image/${detail.id}/${imageId}`,
      successText: "Image deleted successfully",
      modals: [
        {
          title: "Delete Image",
          message: `Are you sure to delete this image?`,
          cancelButton: {
            text: "Cancel",
          },
          confirmButton: {
            text: "Delete",
          },
        },
      ],
    }).then((res) => {
      setImages((images) => {
        return images.filter((image) => image.id !== imageId);
      });
    });
  }

  const getMeta = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = URL.createObjectURL(url);
    });

  return (
    <div>
      <Grid
        container
        spacing={2}
        mb={2}
      >
        <Grid
          item
          xs={6}
        >
          <ImageDropZone
            value={newImage}
            label={"Upload New Image"}
            onChange={setNewImage}
            onUpload={handleSubmit}
          />
        </Grid>
      </Grid>

      {images.map((image) => {
        console.log("@@", process.env.REACT_APP_AWS_S3_MOVIES_URL + image.path);
        return (
          <Grid
            key={"image-" + image.id}
            container
          >
            <Grid
              item
              xs={6}
            >
              <ImageDropZone
                documentId={image.id}
                value={process.env.REACT_APP_AWS_S3_MOVIES_URL + image.path}
                onChange={image}
                onDelete={() => handleDelete(image.id)}
              />
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
}

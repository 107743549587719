import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function DateInput({ value, onChange, label, disabled }) {
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "10px",
          width: "auto",
          color: "#808080",
          fontSize: 13,
          backgroundColor: "white",
          padding: "1px 4px",
          zIndex: 1,
        }}
      >
        {label}
      </div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          className="w-100"
          value={value}
          onChange={onChange}
          disabled={disabled}
        />
      </LocalizationProvider>
    </div>
  );
}

import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import MailButtonModal from "./ButtonModal";
import { languages } from "src/refs";

export default function HtmlEditor({ value, onChange, i18n, warn, warning, placeholder }) {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedLang, setSelectedLang] = useState("en");
  const [modal, setModal] = useState(false);

  if (i18n) {
    value = value[selectedLang];
  }

  function handleMultilanguageChange(event) {
    const val = { ...value };
    val[selectedLang] = event;

    onChange(val);
  }

  return (
    <Box
      sx={{ position: "relative" }}
      className={warn ? "warn" : ""}
    >
      {modal?.type === "button" && (
        <MailButtonModal
          onClose={() => setModal(false)}
          onComplete={modal.onComplete}
        />
      )}
      {i18n && (
        <Typography
          sx={{
            position: "absolute",
            right: "2%",
            top: "2.5%",
            zIndex: 9999,
          }}
        >
          {selectedLang.toUpperCase()}
        </Typography>
      )}
      {loading && (
        <div
          style={{
            height: "350px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Editor
        apiKey={process.env.REACT_APP_EDITOR_KEY}
        ref={editorRef}
        disabled={false}
        value={value}
        inline={false}
        onLoadContent={() => setLoading(false)}
        onEditorChange={i18n ? handleMultilanguageChange : onChange}
        outputFormat="html"
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        init={{
          setup: function (editor) {
            editor.ui.registry.addButton("mailButton", {
              text: "Button",
              enabled: true,
              onAction: function (_) {
                setModal({
                  type: "button",
                  onComplete: (text, link) => {
                    editor.insertContent(`
                        <div align="center" style="padding:0;Margin:0;padding-bottom:10px">
                            <span class="es-button-border" style="border-style:solid;border-color:#cebc51;background:#cebc51;border-width:0px;display:inline-block;border-radius:50px;width:auto">
                                <a href="${link}" class="es-button es-button-1691086688996" target="_blank" style="mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#ffffff;font-size:18px;padding:10px 30px;display:inline-block;background:#cebc51;border-radius:50px;font-family:-apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, Helvetica, Arial, sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\';font-weight:bold;font-style:normal;line-height:22px;width:auto;text-align:center;mso-padding-alt:0;mso-border-alt:10px solid #31CB4B">${text}</a>
                            </span>
                        </div>
                    `);
                  },
                });
              },
            });

            editor.ui.registry.addButton("activationButton", {
              text: "Activation Code",
              enabled: true,
              onAction: function (_) {
                editor.insertContent(`
                    <div align="center" bgcolor="#333333" style="background-color:#333333;padding:10px;Margin:0">
                        <p style="background-color:#333333;Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, \'helvetica neue\', helvetica, sans-serif;line-height:30px;color:#ffffff;font-size:20px">Activation Code</p>
                        <p style="background-color:#333333;Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-family:arial, \'helvetica neue\', helvetica, sans-serif;line-height:30px;color:#ffd700;font-size:20px">#act_code#</p>
                    <div>
                `);
              },
            });

            editor.ui.registry.addNestedMenuItem("language", {
              text: "Language",
              enabled: true,
              getSubmenuItems: function () {
                return languages.current.map((language) => ({
                  type: "menuitem",
                  text: language.name,
                  onAction: function () {
                    setSelectedLang(language.iso_code);
                  },
                }));
              },
            });
          },
          height: 500,
          language: "en",
          placeholder: placeholder ? placeholder : "Enter content...",
          menubar: "custom",
          menu: {
            custom: {
              title: "Languages",
              items: "language",
            },
          },
          plugins: ["link", "lists", "forecolor", "preview", "wordcount", "code"],
          toolbar:
            "language undo redo | blocks link | bold italic code forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | mailButton | activationButton ",

          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      {warn && <Typography sx={{ color: "red", fontSize: "12px" }}>{warning}</Typography>}
    </Box>
  );
}

// TOOLBARS
// print preview | wordcount

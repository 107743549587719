import axios from "axios";
import { formatBytes } from "./mixins";
import sendRequest from "./service";

export default async function uploadMultipartFile({
  file,
  contentId,
  contentType,
  apiPrefix,
  onProgress,
  duration,
  videoDimensions,
  trailerDimensions,
}) {
  try {
    if (!file) {
      return Promise.reject("File is required");
    }

    const uploadData = await sendRequest.post({
      url: apiPrefix + "/start-upload",
      data: {
        content_id: contentId,
        file_type: file.type,
      },
    });

    const FILE_CHUNK_SIZE = 500000000; // 500MB
    const fileSize = file.size;
    const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
    let uploadedParts = [],
      start,
      end,
      blob,
      uploadedBytes = 0;

    const chunks = new Array(NUM_CHUNKS).fill().map((x, i) => i + 1);
    for (const index of chunks) {
      start = (index - 1) * FILE_CHUNK_SIZE;
      end = index * FILE_CHUNK_SIZE;
      blob = index < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);

      // (1) Generate presigned URL for each part
      let presignedUrl = await sendRequest.post({
        url: apiPrefix + `/get-upload-url`,
        data: {
          file_name: uploadData.Key,
          part_number: index,
          upload_id: uploadData.UploadId,
        },
      });

      let previousUploadedByte = 0;
      // (2) Puts each file part into the storage server
      let uploadRes = await axios.request({
        method: "put",
        url: presignedUrl,
        data: blob,
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (event) => {
          uploadedBytes += event.loaded - previousUploadedByte;
          previousUploadedByte = event.loaded;
          onProgress({
            loaded: formatBytes(uploadedBytes),
            total: formatBytes(fileSize),
            progress: (uploadedBytes / fileSize) * 100,
          });
        },
      });

      uploadedParts.push({
        ETag: uploadRes.headers.etag,
        PartNumber: index,
      });
    }

    // (3) Calls the CompleteMultipartUpload endpoint in the backend server
    let completeUploadResp = await sendRequest.post({
      url: apiPrefix + "/complete-upload",
      data: {
        file_name: uploadData.Key,
        content_type: contentType,
        content_id: contentId,
        parts: uploadedParts,
        upload_id: uploadData.UploadId,
        duration,
        video_dimensions: videoDimensions,
        trailer_dimensions: trailerDimensions,
      },
    });

    return completeUploadResp;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function uploadFile(file, contentId, contentType, onProgress) {
  try {
    const apiPrefix = contentType;
    const res = await sendRequest.post({
      url: apiPrefix + "/get-presigned-url",
      data: {
        content_id: contentId,
        // file_type: fileType,
      },
    });

    await axios.request({
      method: "put",
      url: res.data.signed_url,
      data: file,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (event) => {
        onProgress({
          loaded: formatBytes(event.loaded),
          total: formatBytes(event.total),
          progress: (event.loaded / event.total) * 100,
        });
      },
    });

    return true;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default function addFocusEvent({ onFocus, onBlur }) {
  var hidden, visibilityState, visibilityChange;

  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
    visibilityState = "visibilityState";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
    visibilityState = "msVisibilityState";
  }

  var document_hidden = document[hidden];

  function callback() {
    if (document_hidden != document[hidden]) {
      if (document[hidden]) {
        console.log("@hidden");
        onBlur();
      } else {
        console.log("@shown");
        onFocus();
      }

      document_hidden = document[hidden];
    }
  }

  document.addEventListener(visibilityChange, callback);

  return () => document.removeEventListener(visibilityChange, callback);
}

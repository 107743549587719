import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Main from "./main";
import reportWebVitals from "./reportWebVitals";
import { GlobalProvider } from "./state/state";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { UserProvider } from "./state/userState";

const root = ReactDOM.createRoot(document.getElementById("root"));

const outerTheme = createTheme({
  typography: {
    fontFamily: [
      // "Roboto",
      '"Helvetica Neue"',
      "Helvetica",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      light1: "#36CEFF",
      light2: "#E4749D",
      light3: "#fff",
      main: "#E4749D",
      dark1: "#000",
      dark2: "#000",
      contrastText: "#fff",
    },
    secondary: {
      main: "#63E1D2",
      contrastText: "#fff",
    },
    success: {
      main: "#6DE26B",
      contrastText: "#fff",
    },
    error: {
      main: "#E16363",
      contrastText: "#fff",
    },
    warning: {
      main: "#E1D563",
      contrastText: "#fff",
    },
    info: {
      main: "#63D2E1",
      contrastText: "#fff",
    },
  },
});

root.render(
  <React.Suspense fallback={false}>
    <UserProvider>
      <GlobalProvider>
        <ThemeProvider theme={outerTheme}>
          <ToastContainer />
          <Main />
        </ThemeProvider>
      </GlobalProvider>
    </UserProvider>
  </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Drawer,
  Grid,
  useMediaQuery,
} from "@mui/material";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";
import { BrowserView, MobileView } from "react-device-detect";

// project imports
import MenuList from "./MenuList";
import LogoSection from "../Header/LogoSection";

// ==============================|| SIDEBAR DRAWER ||============================== //
const Sidebar = ({ drawerOpen, drawerToggle }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));

  console.log(matchUpMd);

  const width = matchUpMd ? "15vw" : "250px";
  return (
    <Box
      component="nav"
      sx={{ width, minWidth: "220px" }}
      aria-label="mailbox folders"
    >
      <Drawer
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={!!drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width,
            minWidth: "220px",
            background: theme.palette.background.default,
            paddingBottom: "70px",
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "63px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        <Box sx={{ display: { xs: "block", md: "none" } }}></Box>
        <BrowserView>
          <PerfectScrollbar
            component="div"
            style={{
              width: "100%",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <MenuList />
          </PerfectScrollbar>
        </BrowserView>

        <MobileView>
          <Grid
            pt={2}
            pb={2}
            pl={5}
          >
            <LogoSection />
          </Grid>
          <Box sx={{ px: 2 }}>
            <MenuList />
          </Box>
        </MobileView>
      </Drawer>
    </Box>
  );
};

export default Sidebar;

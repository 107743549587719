import React, { useContext, useEffect, useState } from "react";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import { AppBar, Box, CssBaseline, Toolbar } from "@mui/material";

// project imports
import Header from "./MainLayout/Header";
import Sidebar from "./MainLayout/Sidebar";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Loading from "./components/Loading";

import { UserContext } from "./state/userState";
import { permissionMap } from "./permissionHandler";
import { MainContext } from "./state/state";
import Modal from "./modal";
import sendRequest from "./helpers/service";
import ForbiddenPage from "./screens/ErrorPages/403";

import MovieEdit from "./screens/Movie/Page";
import MusicEdit from "./screens/Music/Page";
import MusicVideoEdit from "./screens/MusicVideo/Page";
import MusicAlbumEdit from "./screens/MusicAlbums/Page";

// const Home = React.lazy(() => import("./screens/Home"));

// Dashboard
const GeneralDashboard = React.lazy(() => import("./screens/Dashboard/General"));
const Login = React.lazy(() => import("./screens/Login"));

// Admin
const Admins = React.lazy(() => import("./screens/Admins/List"));
const AdminPage = React.lazy(() => import("./screens/Admins/Page"));

// Roles
const Roles = React.lazy(() => import("./screens/Roles/List"));
const RolePage = React.lazy(() => import("./screens/Roles/Page"));
const RolePages = React.lazy(() => import("./screens/Roles/PageList"));
const CreateRolePage = React.lazy(() => import("./screens/Roles/CreateRolePage"));

// customers
const Customers = React.lazy(() => import("./screens/Customers/List"));
const CreateCustomer = React.lazy(() => import("./screens/Customers/Create"));
const CustomerDetail = React.lazy(() => import("./screens/Customers/Detail"));

//Settings
const ContentSettings = React.lazy(() => import("./screens/Settings/ContentSettings"));
const ContentCacheSettings = React.lazy(() => import("./screens/Settings/Cache"));

//Movie
const MovieList = React.lazy(() => import("./screens/Movie/List"));
const MoviePage = React.lazy(() => import("./screens/Movie/Page"));

//Music
const MusicList = React.lazy(() => import("./screens/Music/List"));
const MusicPage = React.lazy(() => import("./screens/Music/Page"));
const MusicGenreList = React.lazy(() => import("./screens/MusicGenres/List"));

const MusicAlbumList = React.lazy(() => import("./screens/MusicAlbums/List"));
const MusicAlbumPage = React.lazy(() => import("./screens/MusicAlbums/Page"));

const FeaturedMusic = React.lazy(() => import("./screens/MusicFeatures"));

//Music Video
const MusicVideo = React.lazy(() => import("./screens/MusicVideo/List"));
const MusicVideoPage = React.lazy(() => import("./screens/MusicVideo/Page"));

//Transaction
const SonyIngestion = React.lazy(() => import("./screens/Transaction/Sony/List"));
const SonyIngestionDetail = React.lazy(() => import("./screens/Transaction/Sony/Detail"));

// styles
const MainStyle = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, isauth }) => {
  const location = useLocation();
  if (location.pathname.includes("403")) {
    return { width: "100%" };
  }
  return {
    ...(isauth === 0 && {
      width: "100%",
    }),
    ...(isauth === 1 && {
      ...theme.typography.mainContent,
      marginTop: "60px",
      paddingTop: "2%",
      paddingLeft: "2%",
      paddingRight: "2%",
      backgroundColor: "rgb(238, 242, 246)",
      minHeight: "calc(100vh - 80px)",
      borderTopLeftRadius: "15px",
      ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up("md")]: {
          marginLeft: "-15vw",
          width: "calc(100% + 15vw)",
        },
        [theme.breakpoints.down("md")]: {
          marginLeft: "20px",
          width: "calc(100% - 20px)",
          padding: "16px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "10px",
          width: "calc(100% - 10px)",
          padding: "16px",
          marginRight: "10px",
        },
      }),
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - 150px)`,
        [theme.breakpoints.down("lg")]: {
          // marginLeft: "40px",
        },
        [theme.breakpoints.down("md")]: {
          marginLeft: "30px",
        },
        [theme.breakpoints.down("sm")]: {
          marginLeft: "10px",
        },
      }),
    }),
  };
});

export default function Navigation() {
  const theme = useTheme();
  const { user, setContactUsCount, logout } = useContext(UserContext);
  const [leftDrawerOpened, setLeftDrawerOpened] = useState(getDrawerStatus());
  window.handleLogout = logout;

  useEffect(() => {
    return;
    (async () => {
      if (user) {
        if (localStorage.getItem("user")) {
          try {
            const countRes = await sendRequest.get({ url: "form/contact-us/open-count" });
            setContactUsCount(countRes.data);
          } catch (error) {
            console.warn("error at count api", error);
          }
        }
      }
    })();
  }, [user]);

  function getDrawerStatus() {
    if (window.innerWidth < 1024) {
      return false;
    }

    let status = localStorage.getItem("left_drawer_status");
    if (!status) {
      status = window.innerWidth > 1024;
    }
    return status;
  }

  const handleLeftDrawerToggle = () => {
    setLeftDrawerOpened(!leftDrawerOpened);
  };

  const routes = [
    //Dashboard
    {
      path: "/",
      pageName: "dashboard",
      component: GeneralDashboard,
    },
    {
      path: "/content-settings",
      pageName: "settings",
      component: ContentSettings,
    },

    //Customer Routes
    {
      path: "/customers",
      pageName: "customers",
      component: Customers,
    },
    {
      path: "/customers/create",
      pageName: "customers",
      component: CreateCustomer,
    },
    {
      path: "/customers/detail/:id",
      pageName: "customers",
      component: CustomerDetail,
    },
    {
      path: "/customers/update/:id",
      pageName: "customers",
      component: CreateCustomer,
    },

    //Admin Routes
    {
      path: "/admins",
      pageName: "admins",
      component: Admins,
    },
    {
      path: "/admins/create",
      pageName: "admins",
      component: AdminPage,
    },
    {
      path: "/admins/edit/:id",
      pageName: "admins",
      component: AdminPage,
    },

    //Role Routes
    {
      path: "/roles",
      pageName: "roles",
      component: Roles,
    },
    {
      path: "/roles/create",
      pageName: "roles",
      component: RolePage,
    },
    {
      path: "/roles/edit/:id",
      pageName: "roles",
      component: RolePage,
    },

    {
      path: "/roles/pages",
      pageName: "roles",
      component: RolePages,
    },
    {
      path: "/roles/pages/create",
      pageName: "roles",
      component: CreateRolePage,
    },
    {
      path: "/roles/pages/edit/:id",
      pageName: "roles",
      component: CreateRolePage,
    },

    //Movie Routes
    {
      path: "/movies",
      pageName: "movies",
      component: MovieList,
    },
    {
      id: "create_movie",
      path: "/movies/create",
      pageName: "create_movie",
      component: MoviePage,
    },
    {
      id: "edit_movie",
      path: "/movies/edit/:id",
      pageName: "edit_movie",
      component: MovieEdit,
    },

    //Music Routes
    {
      path: "/music",
      pageName: "music",
      component: MusicList,
    },
    {
      id: "create_music",
      path: "/music/create",
      pageName: "music",
      component: MusicPage,
    },
    {
      id: "edit_music",
      path: "/music/edit/:id",
      pageName: "music",
      component: MusicEdit,
    },
    {
      id: "music_genre",
      path: "/music-genres",
      pageName: "music",
      component: MusicGenreList,
    },
    {
      id: "music_album",
      path: "/music-album",
      pageName: "music",
      component: MusicAlbumList,
    },
    {
      id: "music_album",
      path: "/music-album/create",
      pageName: "music",
      component: MusicAlbumPage,
    },
    {
      id: "featured_content",
      path: "/featured-content",
      pageName: "music",
      component: FeaturedMusic,
    },
    {
      id: "music_album",
      path: "/music-album/edit/:id",
      pageName: "music",
      component: MusicAlbumEdit,
    },

    //Music Video Routes
    {
      path: "/music-video",
      pageName: "music_video",
      component: MusicVideo,
    },
    {
      path: "/music-video/create",
      pageName: "music_video",
      component: MusicVideoPage,
    },
    {
      path: "/music-video/edit/:id",
      pageName: "music_video",
      component: MusicVideoEdit,
    },
    {
      path: "/sony-ingestion",
      pageName: "sony_ingestion",
      component: SonyIngestion,
    },
    {
      path: "/sony-ingestion/detail/:id",
      pageName: "sony_ingestion",
      component: SonyIngestionDetail,
    },

    {
      path: "/content-cache",
      pageName: "settings",
      component: ContentCacheSettings,
    },
  ];

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <NavigationBar
          user={user}
          leftDrawerOpened={leftDrawerOpened}
          handleLeftDrawerToggle={handleLeftDrawerToggle}
          theme={theme}
        />

        <MainStyle
          theme={theme}
          open={leftDrawerOpened}
          isauth={user ? 1 : 0}
        >
          <Modal />
          <Loading />
          <Routes>
            {routes.map((route) => (
              <Route
                id={route.id}
                key={route.id || "route-item-" + route.path}
                path={route.path}
                element={
                  <ProtectedRoute
                    accessPageName={route.pageName}
                    component={<route.component />}
                  />
                }
                exact
              />
            ))}

            <Route
              path="/login"
              element={<Login />}
              exact
            />

            <Route
              path="/403"
              element={<ForbiddenPage />}
              exact
            />
            <Route
              path="/*"
              element={<div>Page Not Found</div>}
              exact
            />
          </Routes>
        </MainStyle>
      </Box>
    </Router>
  );
}

const NavigationBar = ({ user, leftDrawerOpened, handleLeftDrawerToggle, theme }) => {
  const location = useLocation();
  window.navigate = useNavigate();

  const { setLoading, setModal } = useContext(MainContext);
  window.setLoading = setLoading;
  window.setModal = setModal;

  if (!user || location.pathname.includes("403") || location.pathname.includes("set-password"))
    return false;

  return (
    <>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          transition: leftDrawerOpened ? theme.transitions.create("width") : "none",
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
        </Toolbar>
      </AppBar>
      <Sidebar
        drawerOpen={leftDrawerOpened}
        drawerToggle={handleLeftDrawerToggle}
      />
    </>
  );
};

const ProtectedRoute = ({ accessPageName, component }) => {
  const { user, userAccessPages } = useContext(UserContext);
  let permissionList = {};

  if (!user) {
    return (
      <Navigate
        to="/login"
        replace
      />
    );
  }

  let permissionFlag = false; // !! redirect to 403 if its true
  const permissionConfig = permissionMap[accessPageName];

  // !! if the page's permission config is not saved in to "permissionConfig", it will redirect to 403
  if (!permissionConfig) {
    permissionFlag = true;
    console.warn("You need to save the configuration for" + accessPageName);
  } else {
    for (const key in permissionConfig) {
      const permission = userAccessPages.find((page) => page.page_id === key);
      permissionList[key] = permission;

      if (permissionConfig[key] && !permission?.read) {
        permissionFlag = true;
        break;
      }
    }
  }

  if (permissionFlag) {
    return (
      <Navigate
        to="/403"
        replace
      />
    );
  }

  return React.cloneElement(component, {
    permission: permissionList,
  });
};

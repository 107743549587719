import { toast } from "react-toastify";

export default function handleError(error) {
  let message = error?.response?.data?.message || error?.message || "Unexpected Error Occured.";

  if (error?.response?.status === 403) {
    message = "You don't have access to this resource!";
  } else if (error?.response?.status === 401) {
    message = "Your session has timed out. Please login again.";
  }

  if (window.setLoading) window.setLoading(false);

  toast(message, {
    theme: "colored",
    type: "error",
  });
}

export const mainReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_SNACK":
      return { ...state, snackbar: action.payload };
    case "SET_BREADCRUMB":
      return { ...state, breadcrumb: action.payload };
    case "SET_PROJECT_CREATE_STEP_ENUM":
      return { ...state, projectCreateStepEnum: action.payload };
    case "SET_STATUS_ENUM":
      return { ...state, statusEnum: action.payload };
    case "SET_PAYMENT_TERM_ENUM":
      return { ...state, paymentTermEnum: action.payload };
    case "SET_COMPANY_TYPE_ENUM":
      return { ...state, compnayTypeEnum: action.payload };
    case "SET_USER":
      return { ...state, user: action.payload };
    case "SET_USER_ACCESS_PAGES":
      return { ...state, userAccessPages: action.payload };
    case "SET_COUNTACT_US_COUNT":
      return { ...state, contactUsCount: action.payload };
    case "LOGOUT":
      return { user: false, userAccessPages: [] };
    case "SET_MODAL":
      return { ...state, modal: action.payload };
    default:
      return state;
  }
};
